@import "css/_dimensions";
@import "css/_colors";

$arrowSize: 20px;
$hoverColor: rgb(91, 96, 98);
$typeWriterMargin: 9px;

.title,
.description,
.question,
.option,
.index {
  composes: unselectable from "css/properties/unselectable.module.scss";
}

.cursor {
  visibility: hidden;
}

.contentsContainer {
  height: 100%;
  width: 100%;
  padding: 20px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  border-radius: 10px;
  border: 2px solid rgb(255, 255, 255, 0.8);
  background: rgb(32,37,46);
  background: -moz-radial-gradient(circle, rgba(32,37,46,1) 0%, rgba(32,36,42,1) 18%, rgba(24,26,31,1) 46%, rgba(24,27,33,1) 81%);
  background: -webkit-radial-gradient(circle, rgba(32,37,46,1) 0%, rgba(32,36,42,1) 18%, rgba(24,26,31,1) 46%, rgba(24,27,33,1) 81%);
  background: radial-gradient(circle, rgba(32,37,46,1) 0%, rgba(32,36,42,1) 18%, rgba(24,26,31,1) 46%, rgba(24,27,33,1) 81%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#20252e",endColorstr="#181b21",GradientType=1);
  box-shadow:
    0 12.5px 75px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 275px;
  // max-height: 450px;
  max-height: 500px;

  // height: 500px;
  position: relative;
  color: rgba(255, 255, 255, 0.8);

  width: 45vh;
  pointer-events: all;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  letter-spacing: 1px;
  text-align: center;
}

.descriptionContainer {
  width: calc(100% - 20px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 7px 10px;

  :global {
    .Typewriter {
      :local {
        .description {
          line-height: 1;
        }
      }
    }
  }
}

.description {
  width: 100%;
  text-align: left;
  letter-spacing: 1px;
  font-size: 10px;
  overflow: hidden;
  // Typewriter formatting prevents line height from being smaller
  line-height: 1.7;
}

.questionContainer {
  width: calc(100% - 20px);
  :global {
    .Typewriter {
      margin-bottom: 5px;
      :local {
        .question {
          line-height: 1;
        }
      }
    }
  }
}

.question {
  text-align: left;
  margin-top: auto;
  margin-bottom: 5px;
  margin-right: auto;

  font-size: 11px;
  font-weight: bold;
  line-height: 1.7;
}

.optionsContainer {
  width: 100%;
  text-align: left;
  gap: 5px;
  font-size: 12px;
  display: flex;
  flex-direction: column;

  .option {
    display: flex;
    align-items: center;
    border: 2px solid rgba(255, 255, 255, 0);
    border-radius: 5px;
    box-sizing: border-box;
  }

  .optionText {
    width: calc(100% - $arrowSize);
    max-height: 100px;
    opacity: 100%;
    font-size: 10px;

    transition: max-height 1s ease-in-out, opacity 1s ease-in-out;

    overflow: hidden;

    &.optionPreview {
      max-height: 0;
      opacity: 0;
    }

    &.appear {
      transition: none;
    }
}

// TODO Move outside
// .option {
//   display: flex;
//   align-items: center;
//   border: 2px solid rgba(255, 255, 255, 0);
//   border-radius: 5px;
//   box-sizing: border-box;
// }

// .optionText {
//   width: calc(100% - $arrowSize);
//   max-height: 100px;
//   opacity: 100%;
//   font-size: 10px;

//   transition:
//     max-height 1s ease-in-out,
//     opacity 1s ease-in-out;

//   overflow: hidden;

//   &.optionPreview {
//     max-height: 0;
//     opacity: 0;
//   }

//   &.appear {
//     transition: none;
//   }

  .up,
  .right,
  .down,
  .left {
    height: $arrowSize;
    width: $arrowSize;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .up {
    transform: rotate(270deg) scaleY(-1);
    // transform: rotate(270deg);
  }

  .right {
  }

  .down {
    transform: rotate(90deg);
  }

  .left {
    // transform: rotate(180deg) scaleX(-1);
    transform: rotate(180deg);
  }
}

.index {
  position: absolute;
  font-size: 7px;
  bottom: 7px;
  right: 10px;
  font-weight: bold;
}

@media screen and (min-width: 480px) {
  .contentsContainer {
    border-width: 5px;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  .descriptionContainer {
    padding: 0 10px 20px;
  }

  .description {
    font-size: 12px;
  }

  .questionContainer {
    width: calc(100% - 20px);
    :global {
      .Typewriter {
        margin-bottom: $typeWriterMargin;
      }
    }
  }

  .question {
    font-weight: bold;
    font-size: 13px;
    // To look the same, the margin has to be = Typewriter - 1
    margin-bottom: calc($typeWriterMargin - 1px);
  }

  .optionsContainer {
    font-size: 12px;

    .optionText {
      font-size: 12px;
    }
  }
}

@media screen and (min-width: $mobileBreakpoint) {
  .contentsContainer {
    max-width: 450px;
    max-height: 650px;
  }
}
