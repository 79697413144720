// Define Mixins
@mixin inactiveMixin {
  content: "";
  position: absolute;
  display: block;
}

@mixin beforeAnimation {
  transition: .2s cubic-bezier(.24, 0, .5, 1);
}

@mixin afterAnimation {
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, .04), 0 4px 9px hsla(0, 0%, 0%, .13), 0 3px 3px hsla(0, 0%, 0%, .05);
  transition: .35s cubic-bezier(.54, 1.60, .5, 1);
}


// Variables (example values)
$lightgray: #e4e4e4;
$background: #fff; // Replace with actual background color
$active: rgb(42, 151, 209); // Replace with actual green color

// Mobile Toggle Switch
.toggleWrapper {
  margin: 0;
  height: 34px;
  border-radius: 5px;
  border: none;
  background: none;
  cursor: pointer;

  input {
    &.mobileToggle {
      opacity: 0; // hides checkbox
      position: absolute;
      cursor: pointer;

      & + label {
        position: relative;
        display: inline-block;
        user-select: none;
        transition: .4s ease;
        -webkit-tap-highlight-color: transparent;
        height: 30px;
        width: 50px;
        // border: 1px solid $lightgray;
        border: 2px solid $lightgray;
        border-radius: 60px;
        cursor: pointer;
        

        &:before {
          @include inactiveMixin;
          @include beforeAnimation;
          height: 30px;
          width: 51px;
          top: 0;
          left: 0;
          border-radius: 30px;
        }

        &:after {
          @include inactiveMixin;
          @include afterAnimation;
          background: $background;
          height: 28px;
          width: 28px;
          top: 1px;
          left: 0px;
          border-radius: 60px;
        }
      }

      // When Active
      &:checked {
        & + label:before {
          background: $active; // Active Color
          transition: width .2s cubic-bezier(0, 0, 0, .1);
        }

        & + label:after {
          left: 54px - 30px;
        }
      }
    }
  }
}
