.container {
  text-align: center;
  padding: 0 30%;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 480px) {
  .inner {
    width: 100vw;
  }
}
