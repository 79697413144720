@import "css/_dimensions";

.container {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  min-height: 450px;
  width: 100%;
  box-sizing: border-box;
  color: rgb(var(--accentColor, (255, 255, 255)));
  
  padding: 0 20px;
  overflow: hidden;
  overflow-y: auto;
}

.linkWrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.outbound {
  fill: rgb(var(--accentColor, (255, 255, 255)));
  transform: scale(0.6);
}

.link {
  // color: white;
  color: rgb(var(--accentColor, (255, 255, 255)));

  &:hover {
    -webkit-text-stroke-width: 1px;

    .outbound {
      -webkit-filter: drop-shadow(1px 0 10px rgb(var(--accentColor, (255, 255, 255))));
      filter: drop-shadow(1px 0 10px rgb(var(--accentColor, (255, 255, 255))));
    }
  }
}

@media screen and (min-width: $mobileBreakpoint) {
  .container {
    padding: 0 30%;
  }
}
