@import "css/_dimensions";

.container {
  // position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  min-height: 450px;
  width: 100%;
  box-sizing: border-box;
  color: black;
  overflow: hidden;
  overflow-y: auto;
  font-size: 11px;
  position: relative;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 10px;
}

.admin {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 2;
}

@media screen and (min-width: $mobileBreakpoint) {
  .container {
    padding: 0 20%;
    border-width: 5px;
  }
}
