// $arrowSize: 20px;
// $hoverColor: rgb(91, 96, 98);

.buildContainer {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 450px;
  width: 100%;
  box-sizing: border-box;
  color: white;
  border: 3px orange solid;
}

.options {
  position: absolute;
  top: 20px;
  left: 20px;
}
.options2 {
  position: absolute;
  top: 100px;
  left: 20px;
}
