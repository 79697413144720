@import "css/_dimensions";

@keyframes expandCircle {
  0% {
    // clip-path: circle(0% at 50% 50%);
    // opacity: 1;
  }
  100% {
    clip-path: circle(150% at 50% 50%);
    // opacity: 0;
  }
}

.container {
  position: relative;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  min-height: 450px;
  width: 100%;
  box-sizing: border-box;
  color: rgb(var(--accentColor, (255, 255, 255)));
  padding: 0 20px;
  overflow: hidden;
  overflow-y: auto;

  &>h1 {
    margin-bottom: 10px;
  }
}

.lightTransition {
  // background: rgb(var(--transitionColor)) !important;
  background: white !important;
  animation: expandCircle 0.75s ease-in forwards;
  // animation: expandCircle 30s ease-in forwards;
}
.darkTransition {
  background: linear-gradient(180deg, rgb(59,63,73) 0%, rgb(40,44,52) 35%, rgb(30, 33, 39) 100%) !important;
  // background: blue !important;
  animation: expandCircle 0.75s ease-in forwards;
  // animation: expandCircle 30s ease-in forwards;
}


.settingWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.hint {
  width: 100%;
  font-size: 11px;
  font-style: italic;
  margin-top: 10px;
}

.divider {
  width: 100%;
  // style divider to only be 1 pixel thickness white at 80% opacity
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(var(--accentColor, (255,255,255)), 0.2);
  margin-top: 20px;
  margin-bottom: 20px;
}

@media screen and (min-width: $mobileBreakpoint) {
  .container {
    padding: 0 20%;
  }
}
