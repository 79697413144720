@import "css/_dimensions";

.container {
  height: $headerHeight-mobile;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: rgb(255, 255, 255, 0.05);
  background-color: rgba(var(--accentColor), 0.05);
  position: relative;
  z-index: 999;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
.navContainer {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 500px;
  justify-content: space-evenly;
  gap: 5px;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
}

.navLink {
  composes: unselectable from "css/properties/unselectable.module.scss";
  font-size: 10px;
  // color: white;
  color: rgb(var(--accentColor, (255,255,255)));
  flex-shrink: 1;
  flex-grow: 1;
  transition: flex-grow 1000ms linear;
  text-align: center;
  height: 100%;
  border-radius: 10px;

  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  &>:first-child {
    // border: 1px solid red;
    width: 24px;
    height: 24px;
  }

  .headerIcon {
    // fill: white;
    // fill: rgb(var(--accentColor, rgb(255,255,255)));
    fill: rgb(var(--accentColor, (255,255,255)));
  }

  &.activeLink {
    position: relative;

    .headerIcon {
      // -webkit-filter: drop-shadow(1px 0 5px rgba(255, 255, 255, 1));
      // filter: drop-shadow(1px 0 5px rgba(255, 255, 255, 1));
      -webkit-filter: drop-shadow(1px 0 5px rgba(var(--accentColor), 1));
      filter: drop-shadow(1px 0 5px rgba(var(--accentColor), 1));
    }
  }
}



.indicator {
  position: absolute;
  align-self: center;
  left: 0;
  height: calc(100% - 10px);
  // background-color: rgb(255, 255, 255, 0.1);
  // background-color: rgb(255, 255, 255, 0.15);
  background-color: rgba(var(--accentColor), 0.15);
  transition:
    left 0.2s ease,
    width 0.2s ease;
  border-radius: 10px;
  pointer-events: none;
}

@media screen and (min-width: $mobileBreakpoint) {
  .container {
    height: $headerHeight-desktop;
  }

  .navLink {
    font-size: 10px;

    &.activeLink {
      text-shadow:
        // #ffffff 1px 0 10px,
        // #ffffff 1px 0 10px;
        rgb(var(--accentColor)) 1px 0 10px,
        rgb(var(--accentColor)) 1px 0 10px;
    }

    .headerIcon {
      // -webkit-filter: drop-shadow(1px 0 10px rgba(255, 255, 255, 1));
      // filter: drop-shadow(1px 0 10px rgba(255, 255, 255, 1));
      -webkit-filter: drop-shadow(1px 0 10px rgba(var(--accentColor), 1));
      filter: drop-shadow(1px 0 10px rgba(var(--accentColor), 1));
    }
  }
}
