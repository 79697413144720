
@import "css/_colors";
@import "css/_dimensions";

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: var(--thirdColor);
  box-sizing: border-box;

  .numberContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .circle {
      height: 15px;
      width: 15px;
      background-color: none;
      border-radius: 50%;
      border: black 1px solid;
    }
  
    .number {
      white-space: pre;
      font-size: 8px;
    }
  }

  .name {
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
  }


  h4 {
    text-align: left;
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 6px;
    letter-spacing: 2px;
    transform-origin: left;
  }

  p {
    margin: 0;
    font-weight: bold;
  }

  .resultElement {
    // margin-top: 12px;
    margin-top: 0px;
    // font-size: 24px;
    font-size: 18px;
  }

  .resultTitle {
    font-size: 8px;
    text-transform: uppercase;
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    padding-top: 10px;
    width: 40%;
  }

  .typeContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5px;
    padding-top: 10px;
    width: 60%;
    text-align: end;
    background-color: $washedGrey;
    z-index: 1;
  }
}

@media screen and ( min-width: $mobileBreakpoint ) {
  h4 {
    font-size: 8px;
  }

  .resultElement {
    font-size: 24px;
  }

  .resultTitle {
    font-size: 10px;
  }

  .userInfo, .typeContainer {
    padding: 10px
  }
}
