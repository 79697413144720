@import "css/_dimensions";
@import "css/_colors";

$arrowSize: 20px;
$hoverColor: rgb(91, 96, 98);

.deck {
  composes: unselectable from "css/properties/unselectable.module.scss";
  position: absolute;
  width: 450px;
  width: 100%;
  color: white;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
  // top: 47%
  height: 100%;
  box-sizing: border-box;
  pointer-events: none;
  // background-color: red;
  z-index: inherit;
}

.card {
  composes: unselectable from "css/properties/unselectable.module.scss";
  // width: 45vh;
  // max-width: 275px;
  height: 100%;
  max-height: 500px;
  // height: 100%;
  will-change: transform;
  // position: relative;
  opacity: 0;
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // Needs a border for child cards to inherit
  border: rgba(0, 0, 0, 0) 2px solid;
  background-color: none;
  // pointer-events: none;
}

@media screen and (min-width: $mobileBreakpoint) {
  .card {
    // height: 100%;
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
  }
}

@media screen and (min-height: 720px) {
  .card {
    max-height: none;
  }
}
