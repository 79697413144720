@import "css/_colors";
@import "css/_dimensions";

.footer {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background-color: var(--thirdColor);
  position: relative;
  z-index: 1;
  font-size: 8px;
  font-weight: bold;
}
.invert {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  // mix-blend-mode: multiply;
  z-index: -1;
}

@media screen and (min-width: $mobileBreakpoint) {
  .footer {
    font-size: 10px;
  }
}
