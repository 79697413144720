@import "css/_dimensions";

body {
  background: linear-gradient(180deg, rgb(var(--themeColor1, rgb(59,63,73))) 0%, rgb(var(--themeColor2, rgb(40,44,52))) 35%, rgb(var(--themeColor3, rgb(30, 33, 39))) 100%);
  transition: background-color 0.75s ease;
  // background: red;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.appContainer {
  height: 100%;
  height: 100vh;
  // height: 100svh;
  // 100svh ends up with a 1 pixel white line on the bottom of mobile
  // height: calc(100% + 1px);
  // height: calc(100vh + 1px);
  height: calc(100svh + 1px);
  box-sizing: border-box;
  background: rgb(var(--themeColor1, (59,63,73)));
  background: linear-gradient(180deg, rgb(var(--themeColor1, rgb(59,63,73))) 0%, rgb(var(--themeColor2, rgb(40,44,52))) 35%, rgb(var(--themeColor3, rgb(30, 33, 39))) 100%);
  // background: linear-gradient(180deg, rgb(var(--xx, rgb(59,63,73))) 0%, rgb(var(--xx, rgb(40,44,52))) 35%, rgb(var(--xx, rgb(30, 33, 39))) 100%);
  // background-color: red;
  // transition: background-color 0.75s ease;
  transition: background-color 0.75s ease;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.pageContainer {
  height: calc(100% - $headerHeight-mobile);
  z-index: 100;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: $mobileBreakpoint) {
  .pageContainer {
    height: calc(100% - $headerHeight-desktop);
  }
}
