@import "css/_dimensions";
@import "css/_colors";

:root {
  --baseColor: rgb(168, 116, 76);
  --secondaryColor: rgb(87, 141, 67);
  --thirdColor: rgb(194, 178, 128);
  --fourthColor: rgb(120, 91, 59);
  // --accentColor: rgb(255, 204, 92);
  --darkText: black;
  --lightText: white;

  // --baseColor: #a8744c;
  // --secondaryColor: #578d43;
  // --thirdColor: #c2b280;
  // --fourthColor: #785b3b;
  // --accentColor: #ffcc5c;
}

.overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: black;
  mix-blend-mode: difference;
  z-index: 1000;
  pointer-events: none;

  transition: background-color 1s ease;
}

// .elementIcon {
//   composes: unselectable from "css/properties/unselectable.module.scss";
//   pointer-events: none;
// }

.resultCard {
  composes: unselectable from "css/properties/unselectable.module.scss";
  color: black;
  background-color: var(--baseColor);
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  border: 2px solid var(--fourthColor);
  border-color: var(--fourthColor);

  // Inherit border thickness and radius from animated div parent
  border-radius: inherit;
  border-width: inherit;
  // rare content box use so that react-spring animation doesn't jump at the end
  box-sizing: content-box;
  // letter spacing is so that html2canvas can space out the words properly
  letter-spacing: 0.0001px;
  // letter-spacing: 1px;
  display: flex;
  flex-direction: column;
  // position: absolute;
  z-index: 1000;
  // overflow-y: scroll;
  position: absolute;
  width: revert;
  max-width: 275px;
  // We don't want height: 100% because we want it to fit the card
  // height: 100%;
  pointer-events: all;
  // box-sizing: border-box;
}

.admin {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 2;
}

@media screen and (min-width: $mobileBreakpoint) {
  .resultCard {
    width: 45vh;
    // border-width: 5px;
    max-width: 450px;
  }
}
